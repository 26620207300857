<script setup>
const props = defineProps(["singleBible", "items"]);
const emit = defineEmits(["change"]);

const chaptersTotal = computed(() => props.items || 0);

const isSelected = (chapterNum) => {
  return props.singleBible?.location.value?.chapterNum == chapterNum;
};

function handleSelectBookChapter(chapterNum) {
  emit("change", {
    singleBible: props.singleBible,
    chapterNum,
  });
}
</script>

<template>
  <div class="v-chapters-list">
    <!-- <div class="my-2 ml-1">Розділ:</div> -->
    <v-btn
      v-for="chapterNum in chaptersTotal"
      :value="chapterNum"
      @click="handleSelectBookChapter(chapterNum)"
      class="chapter-btn ma-1"
      :class="{
        active: isSelected(chapterNum),
      }"
    >
      {{ chapterNum }}
    </v-btn>
  </div>
</template>
<style scoped>
.chapter-btn {
  display: inline-flex;
  border-radius: 8px;
  border: solid 1px #bbc2d4;
  color: #292937;
  font-size: 17px;
  min-width: 35px;
  height: 35px;
  text-align: center;
  line-height: 37px;
  box-sizing: border-box;
}

.chapter-btn:hover {
  background-color: #e4e4e4;
}

.chapter-btn.active {
  border: none;
}

.v-chapters-list {
  max-width: 300px;
  flex-wrap: wrap;
}
.active {
  color: var(--root-white);
  background-color: var(--root-green) !important;
}
</style>
